export const Bio = {
  name: "Adithya M",
  roles: ["Full Stack Developer", "Programmer", "Tinkerer"],
  description:
    "Motivated and adaptable professional with a strong passion for continuous learning and technological advancement.",
  github: "https://github.com/adithya-m08",
  resume: "https://drive.google.com/file/d/1uhhfmwGToggeo86VZNTfgxm19deAbqNY/view?usp=sharing",
  linkedin: "https://www.linkedin.com/in/adithya-m08/",
};

export const skills = [
  {
    title: "Frontend",
    skills: [
      {
        name: "React Js",
        image: "logos/react.svg",
      },
      {
        name: "Flutter",
        image: "logos/flutter.svg",
      },
      {
        name: "HTML",
        image: "logos/html.svg",
      },
      {
        name: "CSS",
        image: "logos/css.svg",
      },
      {
        name: "JavaScript",
        image: "logos/js.svg",
      },
    ],
  },
  {
    title: "Backend",
    skills: [
      {
        name: "Spring Boot",
        image: "logos/springboot.svg",
      },
      {
        name: "Node Js",
        image: "logos/nodejs.svg",
      },
      {
        name: "Graph Ql",
        image: "logos/graphql.svg",
      },
      {
        name: "MySQL",
        image: "logos/mysql.svg",
      },
      {
        name: "MongoDB",
        image: "logos/mongodb.svg",
      },
    ],
  },
  {
    title: "Languages",
    skills: [
      {
        name: "Python",
        image: "logos/python.svg",
      },
      {
        name: "Java",
        image: "logos/java.svg",
      },
      {
        name: "Dart",
        image: "logos/dart.svg",
      },
      {
        name: "C++",
        image: "logos/cpp.svg",
      },
    ],
  },
  {
    title: "Others",
    skills: [
      {
        name: "Git",
        image: "logos/git.svg",
      },
      {
        name: "Android Studio",
        image: "logos/androidstudio.svg",
      },

      {
        name: "Docker",
        image: "logos/docker.svg",
      },
      {
        name: "VS Code",
        image: "logos/vscode.svg",
      },
      {
        name: "Postman",
        image: "logos/postman.svg",
      },
    ],
  },
];

export const experiences = [
  {
    id: 0,
    img: "logos/bt.png",
    role: "Software Engineering Professional",
    company: "British Telecom",
    date: "August 2024 - Present",
    desc: "Working on My EE App",
    skills: ["Flutter", "Dart", "Wiremock", "Android Studio"],
  },
  {
    id: 1,
    img: "logos/bt.png",
    role: "Associate Engineering Intern",
    company: "British Telecom",
    date: "January 2024 - July 2024",
    desc: "Boosted code coverage of react components by 35% and refactored code to increase code reuse.",
    skills: ["ReactJS", "Jest", "Wiremock", "NodeJs", "JavaScript"],
  },
  {
    id: 2,
    img: "logos/nasdaq.png",
    role: "QA Testing Intern",
    company: "Nasdaq",
    date: "June 2023 - December 2023",
    desc: `Implemented a PyTest plugin to generate portable HTML reports and automate post-test reporting.
    Integrated the test repository, qTest, with the testing framework to efficiently retrieve and analyze execution results.`,
    skills: ["Pytest", "Python", "Black Box Testing", "qTest"],
  },
  {
    id: 3,
    img: "logos/ieeeras.png",
    role: "Treasurer and Competition Body",
    company: "IEEE RAS PESU",
    date: "November 2021 - December 2023",
    desc: "Developed and Inaugurated India's first 24/7 Remote Robotics (ReRo) Lab. Worked alongside a team of 40 core members and 700+ community members to organise events and workshops.",
    skills: ["Robotics", "Team Management", "Arduino", "OpenCV", "Treasury"],
  },
  {
    id: 4,
    img: "logos/pesuio.jpeg",
    role: "Subject Matter Expert",
    company: "PESU I/O",
    date: "June 2022 - February 2023",
    desc: "DIY: Drone It Yourself. Taught a class of 30 students about the basics of an UAV, programming and simulating an UAV using the 'dronekit' library.",
    skills: ["Python", "OpenCV", "DroneKit", "UAV", "Teaching"],
  },
];

export const projects = [
  {
    id: 0,
    title: "Mixed Reality Simulator for Drone Piloting",
    date: "Dec 2022 - Dec 2023",
    description:
      "Engineered a mixed-reality flight simulator that enables drone pilots to train in either a virtual environment or by streaming 360-degree real-time footage from an actual drone, significantly improving training effectiveness by providing both simulated and real-world flight experience",
    image: "MRSimulator.png",
    tags: ["Unity", "C#", "ArduPilot", "Meta Quest"],
    category: "others",
  },
];

export const education = [
  {
    id: 0,
    img: "logos/pesu.jpg",
    school: "PES University, Ring Road Campus, Bangalore",
    date: "August 2021 - May 2024",
    grade: "8.79 CGPA",
    desc: "Bachelor's degree in Computer Science and Engineering. My coursework included Data Structures and Algorithms, Database Management Systems, Operating Systems, and Computer Networks, among others. During my time at PES University, I was actively involved with the IEEE RAS PESU Robotics Club, serving as a treasurer and contributing to the competition body.",
    degree: "Bachelor of Technology - BTech, Computer Science and Engineering",
  },
  {
    id: 1,
    img: "logos/narayana.png",
    school: "Narayana College, Bangalore",
    date: "April 2018 - April 2020",
    grade: "95.4%",
    desc: "Graduated in CBSE Computer Science with 97 Percentile in JEE Mains.",
    degree: "CBSE XI-XII",
  },
  {
    id: 2,
    img: "logos/ois.jpg",
    school: "Orchids the International School Vivekananda",
    date: "February 2008 - February 2018",
    grade: "93%",
    desc: "Completed Class 10 in ICSE syllabus at Orchids the International School, Bangalore, where I studied Science with Computer Applications.",
    degree: "ICSE X",
  },
];
