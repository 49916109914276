import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import emailjs from "@emailjs/browser";
import { Snackbar } from "@mui/material";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  align-items: center;
  @media (max-width: 960px) {
    padding: 0px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1350px;
  padding: 0px 0px 80px 0px;
  gap: 12px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const Title = styled.div`
  font-size: 42px;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 32px;
  }
`;

const Desc = styled.div`
  font-size: 18px;
  text-align: center;
  max-width: 600px;
  color: ${({ theme }) => theme.text_secondary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 16px;
  }
`;

const ContactForm = styled.form`
  width: 95%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.card};
  padding: 32px;
  border-radius: 16px;
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  margin-top: 28px;
  gap: 12px;
`;

const ContactTitle = styled.div`
  font-size: 24px;
  margin-bottom: 6px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_primary};
`;

const ContactInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ContactInput = styled.input`
  flex: 1;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.text_secondary};
  outline: none;
  font-size: 18px;
  color: ${({ theme }) => theme.text_primary};
  border-radius: 12px;
  padding: 12px 16px;
  &:focus {
    border: 1px solid ${({ theme }) => theme.primary};
  }
`;

const ContactInputMessage = styled.textarea`
  flex: 1;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.text_secondary};
  outline: none;
  font-size: 18px;
  color: ${({ theme }) => theme.text_primary};
  border-radius: 12px;
  padding: 12px 16px;
  &:focus {
    border: 1px solid ${({ theme }) => theme.primary};
  }
`;

const ContactButton = styled.input`
  width: 100%;
  text-decoration: none;
  text-align: center;
  background: linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(294, 100%, 50%, 1) 100%);
  padding: 13px 16px;
  margin-top: 2px;
  border-radius: 12px;
  border: none;
  color: ${({ theme }) => theme.text_primary};
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  &:disabled {
    background: grey;
    cursor: not-allowed;
  }
  &:active {
    transform: scale(0.98);
  }
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.text_secondary};
  font-size: 14px;
  text-align: left;
  opacity: 0.8;
`;

const GeneralErrorMessage = styled(ErrorMessage)`
  text-align: center;
  margin-top: 12px;
`;

const Contact = () => {
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState({
    from_email: "",
    from_name: "",
    subject: "",
    message: "",
  });
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [showGeneralError, setShowGeneralError] = useState(false);
  const [emailError, setEmailError] = useState("");
  const form = useRef();

  useEffect(() => {
    emailjs.init(process.env.REACT_APP_EMAILJS_USER_ID);
  }, []);

  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(formValues.from_email));
    setEmailError(isValidEmail ? "" : "Invalid email address");
  }, [formValues.from_email, isValidEmail]);

  useEffect(() => {
    setShowGeneralError(!Object.values(formValues).every((value) => value.trim() !== ""));
  }, [formValues]);

  const isFormValid = isValidEmail && Object.values(formValues).every((value) => value.trim() !== "");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        () => {
          setOpen(true);
          form.current.reset();
          setFormValues({ from_email: "", from_name: "", subject: "", message: "" });
          setIsSubmitting(false);
        },
        (error) => {
          console.error("Failed to send email:", error.text);
          setIsSubmitting(false);
        }
      );
  };

  return (
    <Container id="contact">
      <Wrapper>
        <Title>Contact</Title>
        <Desc>Feel free to reach out to me for any questions or opportunities!</Desc>
        <ContactForm ref={form} onSubmit={handleSubmit}>
          <ContactTitle>Email Me 🚀</ContactTitle>
          <ContactInputWrapper>
            <ContactInput
              placeholder="Your Email"
              name="from_email"
              value={formValues.from_email}
              onChange={handleChange}
            />
            {!isValidEmail && <ErrorMessage>{emailError}</ErrorMessage>}
          </ContactInputWrapper>
          <ContactInputWrapper>
            <ContactInput
              placeholder="Your Name"
              name="from_name"
              value={formValues.from_name}
              onChange={handleChange}
            />
          </ContactInputWrapper>
          <ContactInputWrapper>
            <ContactInput
              placeholder="Subject"
              name="subject"
              value={formValues.subject}
              onChange={handleChange}
            />
          </ContactInputWrapper>
          <ContactInputWrapper>
            <ContactInputMessage
              placeholder="Message"
              rows="4"
              name="message"
              value={formValues.message}
              onChange={handleChange}
            />
          </ContactInputWrapper>
          <ContactButton type="submit" value="Send" disabled={!isFormValid || isSubmitting} />
          {showGeneralError && <GeneralErrorMessage>All fields are mandatory!</GeneralErrorMessage>}
        </ContactForm>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={() => setOpen(false)}
          message="Email sent successfully!"
        />
      </Wrapper>
    </Container>
  );
};

export default Contact;
