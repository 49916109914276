import React from "react";
import { useState } from "react";
import {
  Container,
  Wrapper,
  Title,
  Desc,
  CardContainer,
  ToggleButtonGroup,
  ToggleButton,
  Divider,
} from "./ProjectsStyle";
import ProjectCard from "../Cards/ProjectCards";
import { projects } from "../../data/constants";

const Projects = ({ openModal, setOpenModal }) => {
  const [toggle, setToggle] = useState("all");
  return (
    <Container id="projects">
      <Wrapper>
        <Title>Projects</Title>
        <Desc>This section is a work in progress, Stay Tuned!</Desc>
        <ToggleButtonGroup>
          {toggle === "all" ? (
            <ToggleButton active value="all" onClick={() => setToggle("all")}>
              All
            </ToggleButton>
          ) : (
            <ToggleButton value="all" onClick={() => setToggle("all")}>
              All
            </ToggleButton>
          )}
          <Divider />
          {toggle === "web" ? (
            <ToggleButton active value="web" onClick={() => setToggle("web")}>
              Web
            </ToggleButton>
          ) : (
            <ToggleButton value="F" onClick={() => setToggle("web")}>
              Web
            </ToggleButton>
          )}
          <Divider />
          {toggle === "android" ? (
            <ToggleButton active value="android" onClick={() => setToggle("android")}>
              Android
            </ToggleButton>
          ) : (
            <ToggleButton value="android" onClick={() => setToggle("android")}>
              Android
            </ToggleButton>
          )}
          <Divider />
          {toggle === "others" ? (
            <ToggleButton active value="others" onClick={() => setToggle("others")}>
              Others
            </ToggleButton>
          ) : (
            <ToggleButton value="others" onClick={() => setToggle("others")}>
              Others
            </ToggleButton>
          )}
        </ToggleButtonGroup>
        <CardContainer>
          {toggle === "all" &&
            projects.map((project) => (
              <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal} />
            ))}
          {projects
            .filter((item) => item.category === toggle)
            .map((project) => (
              <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal} />
            ))}
        </CardContainer>
      </Wrapper>
    </Container>
  );
};

export default Projects;
